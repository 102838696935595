import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
    private restrictedAccess: boolean = true;

    constructor() {}
    
    getRestrictedAccess(): boolean {
        return this.restrictedAccess;
    }

    setRestrictedAccess(flag: boolean) {
        console.log("restrictedAccess was set to: ", flag);
        this.restrictedAccess = flag;
    }
}