import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Account, AccountCollection } from '@mitel/cloudlink-sdk/admin';
import { AdminService as CloudAdmin, Config, Odata, Utils } from '@mitel/cloudlink-sdk';
import { ErrorsHandlerService } from './errors-handler.service';

@Injectable({providedIn: 'root'})
export class AdminService {
	private readonly api: CloudAdmin;

	constructor( private errService: ErrorsHandlerService ) {
		Config.cloud = environment.cloud;
		this.api = new CloudAdmin();
	}

	public getAccount(accountId: string): Promise<Account> {
		// console.log('Function: getAccount, GET ACCOUNT:');
		return new Promise<Account>(async (resolve, reject) => {
			try {
				let account = await this.api.getAccount({accountId});
				resolve(account);
			} catch (err) {
				this.errService.handleError(err);
				reject(err);
			}
		});
	}

	public getAllAccounts(odata?: Odata): Promise<Account[]> {
		return new Promise<Account[]>(async (resolve, reject) => {
			let accounts: Account[];
			try {
				// console.log('Function: , odata: ', odata);
				const accountCollection = await this.getAccounts(odata);
				if (accountCollection && accountCollection.count > 0 && accountCollection._embedded && accountCollection._embedded.items) {
					accounts = accountCollection._embedded.items;
					const next = Utils.getOdataNext(accountCollection);
					if (next && next.$SkipToken) {
						const moreAccounts = await this.getAllAccounts(next);
						if (moreAccounts) {
							accounts = accounts.concat(moreAccounts);
						}
					}
				}
				resolve(accounts);
			} catch (err) {
				this.errService.handleError(err);
				reject(err);
			}
		});
	}

	public getAccounts(odata?: Odata, options?: any): Promise<AccountCollection> {
		if (odata) {
			return this.api.getAccounts({
				$Expand: odata.$Expand, $Skip: odata.$Skip,
				$Top: odata.$Top, $SkipToken: odata.$SkipToken, options
			});
		} else {
			return this.api.getAccounts({ options });
		}
	}

	public getAccountTags(accountId: string): Promise<any> {
		return this.api.getAccountTags({accountId}).then( tags => {
			return tags;
		})
		.catch (err => {
			throw(err);
		});
	}
}
