<div class="alert alert-custom" [ngStyle]="externalStyles" id="theAlert" >
	<div class="row"> 
			<div class="col-auto">
				<span class="error-icon">
					<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAWJJREFUOBGlU71KA0EQnjkugmW4FFbGIjYKdnkBQcEHSGMbRcTOB7E7joA+QR5AUPAF0glWpjBWKXJ1wGDG+Sa3594m/uE2OzM73/ztN0zBydvt3TnNTpj5UEg28czEryJyF1HtOhkMnnwIO0U6nbX85flK9XMhipzdv9V5rnqWbG1fcr//hjcLYODR8Faz7PuAr2St7iFpto4QxDIh82/BCArfolpi9Cw0e6yUzTxVr/VKBYEN7TDV9iKh99MQ3Eg26sR8UwZQubBNnQ0YDDsmlgNSrTyaeZKP00azdTYZDc1cyL2wKvxU7L6qDABBpAswgFBVBrgL2T/Arvwu3+knOTaSkOxUHNHzooUe7GU7QRXAxiR8rzV/BtBp68Au/LKtHdjy8bE/B7Dz/99YcDurtBByAI/LtgxYG6JxW+lZCfKNYlTWfYCLBQCnjdtEabEwK+EL9lHq9gBOtky+91/X+QNKML+hmuZ+OwAAAABJRU5ErkJggg==" />
				</span>
			</div>
			<div class="col alert-custom-message" id="theAlertMessage">
				<span [innerHTML]="errorMessage | translate"></span>
			</div>
	</div>
</div>
