import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthenticationService, Config, UserClaims, Token, Claims } from '@mitel/cloudlink-sdk';
import { PolicyTestRequest, PolicyTestResponse } from '@mitel/cloudlink-sdk/authentication';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public auth: AuthenticationService;

    constructor() {
		Config.cloud = environment.cloud;
		this.auth = Config.authentication;
    }

    public getPartnerToken(): Token
    {
        let tokenStr = localStorage.getItem('@mitel/token.original_token');
        if ( tokenStr )
            return  JSON.parse(tokenStr); 

        return null;
    }

    assumeRole( newRole: string, newAccountId: string ): Promise<Token>
    {
        console.log("ASSUME ROLE", newAccountId);
        return this.auth.assumeRole({role: newRole, accountId: newAccountId});
    }

    revertRole(): Promise<Token>{
        if ( this.getPartnerToken() != null )
        {
            console.log("REVERT ROLE");
            return this.auth.revertRole();
        }
    }
    
    testPolicy(request: PolicyTestRequest): Promise<PolicyTestResponse> {
        return this.auth.testPolicy(request);
    }
}

