import { Injectable } from '@angular/core';
import { UserClaims, Token } from '@mitel/cloudlink-sdk';
import { ReplaySubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppUserClaimsService {
	private claims: UserClaims;
	public claimsChanged = new ReplaySubject<UserClaims>(1); // Last 1 and future emits are broadcasted to all subscribed.

  constructor() { }

  getClaims() {
  	return this.claims;
  }

  setClaims(claims: UserClaims) {
  	this.claims = claims;
  	this.claimsChanged.next(this.claims);
  }

  isAssumedRole(): boolean
  {
    if (localStorage.getItem('@mitel/token.original_token'))
      return true;

    return false;
  }
}
