<div *ngIf="orderDetails && orderDetails.licenses" class="list-container">
    <div *ngFor="let license of expandedOrderDetails; let i = index">
        <div class="row-wrapper">
            <div class="product-column">
                {{license.productName}}
            </div>
            <div class="all-details-column">
                <mat-icon 
                    class="clickable-pointer" 
                    tabindex="0"
                    [matTooltip]="license.ratePlanInfo" 
                    svgIcon="info_icon">
                </mat-icon>
                <div id="{{'proDetails' + i}}"  class="details-column" [matTooltip]="license.tooltip">
                    {{license.productDetails}}
                </div>
            </div>
            <div *ngIf="license.numLicenses == 1" class="number-licenses-column">
                {{license.numLicenses}} {{'order_details.license' | translate}}
            </div>
            <div *ngIf="license.numLicenses != 1" class="number-licenses-column">
                {{license.numLicenses}} {{'order_details.licenses' | translate}}
            </div>
        </div>
    </div>
</div>
