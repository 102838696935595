import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { Account } from '@mitel/cloudlink-sdk';
import { AccountsService } from '../../../services/accounts.service';
import { AccountWithSap } from 'src/app/interfaces/account-sap-data';
import { OrdersService } from 'src/app/services/orders.service';
import { BillingAccount } from '@mitel/cloudlink-sdk/billing';
import { ParentCommsService } from 'src/app/services/parent-comms.service';
import { AppStateService } from '../../../services/app-state.service';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})

export class SelectAccountComponent implements OnInit, OnDestroy {
  @Input() accountFormGroup: UntypedFormGroup;
  @Input() excludeAccount: string;
  @Output() selectedAccountChanged = new EventEmitter();

  @ViewChild('singleSelect', {static: false}) singleSelect: MatSelect;

  constructor( private accountService: AccountsService,
    private ordersService: OrdersService,
    private parentCommsService: ParentCommsService,
    private appStateService: AppStateService
  ) {}

  filteredAccounts: ReplaySubject<AccountWithSap[]> = new ReplaySubject<AccountWithSap[]>(1);
  selectedAccount: AccountWithSap = undefined;
  accounts: AccountWithSap[] = [];
  loading = true;
  restrictedAccess: boolean = true;

  protected _onDestroy = new Subject<void>();

  ngOnInit() {
    // set initial selection
    this.accountFormGroup.controls['account'].setValue(undefined);

    // load the initial bank list
    this.filteredAccounts.next(this.accounts.slice());

    // listen for search field value changes
    this.accountFormGroup.controls['accountFilter'].valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAccounts();
      });
    
    /* 
    Check if access is restricted (based on claims, and policy-test).
    If restricted, disable the accounts list and don't bother getting all the accounts.
    */
    this.restrictedAccess = this.appStateService.getRestrictedAccess();
    if(!this.restrictedAccess){
      this.initFormData();
    }
    else{
      this.accountFormGroup.disable();
      this.loading = false;
    }
  }

  initFormData() {
    const promises = [];
    const adminAccountsPromise = this.accountService.getAllActiveAccounts().then( accounts => {
      let adminAccounts;
      if ( this.excludeAccount && accounts )
      {
        adminAccounts = accounts.filter(acc => acc.accountId != this.excludeAccount )
      }
      else
      {
        adminAccounts = accounts;
      }
      return adminAccounts;
    })
    .catch((error) => {
      console.log("Failed to get all active accounts", error);
      return [];
    });
    promises.push(adminAccountsPromise);
    
    const billingAccountsPromise = this.ordersService.getBillingAccounts();
    promises.push(billingAccountsPromise);

    Promise.all(promises).then((values) => {
      this.accounts = this.getAccountsWithSapIds(values[0], values[1]);
     this.filteredAccounts.next(this.accounts.slice());
      const currentAccountId = this.parentCommsService.getCurrentAccountIdToAssignOrder();
      if (currentAccountId) {
        const value = this.accounts.find( account => { return account.accountId === currentAccountId;})
        if (value) {
          this.accountFormGroup.controls['account'].setValue(value);
          this.selectedAccountChanged.emit( this.accountFormGroup.controls['account'].value );
        }
      }
      this.loading = false;
    });
    
  }

  getAccountsWithSapIds(adminAccounts: Account[], billingAccounts: BillingAccount[]): AccountWithSap[] {
    let sapIds: any = {};
    for( let billingAccount of billingAccounts) {
      if ( billingAccount.basicInfo && billingAccount.basicInfo.sapId) {
        sapIds[billingAccount.basicInfo.accountId] = billingAccount.basicInfo.sapId;
      }
    }
    let accountsWithSapIds = [];
    for( let account of adminAccounts) {
      accountsWithSapIds.push({...account, sapId: sapIds[account.accountId]} as AccountWithSap);
    }

    return accountsWithSapIds;
  }
  
  ngAfterViewInit() {
    this.setInitialValue();
  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {
    this.filteredAccounts
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Account, b: Account) => a && b && a.name === b.name;
      });
  }

  protected filterAccounts() {
    if (!this.accounts) {
      return;
    }
    // get the search keyword
    let search = this.accountFormGroup.controls['accountFilter'].value;
    if (!search) {
      this.filteredAccounts.next(this.accounts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredAccounts.next(
      this.accounts.filter(account => account.name.toLowerCase().indexOf(search) > -1 || (account.sapId && account.sapId.indexOf(search) > -1))
    );
  }

  onSelectAccount()
  {
    this.selectedAccountChanged.emit( this.accountFormGroup.controls['account'].value );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
