import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuardService } from './services/route-guard.service';
import { OrdersListPageComponent } from './components/orders-list-page/orders-list-page.component';

const routes: Routes = [
  { path: '', component: OrdersListPageComponent, canActivate: [RouteGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [RouteGuardService]
})
export class AppRoutingModule { }
