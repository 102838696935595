import { Component, OnInit, OnDestroy, Input, HostListener, Inject, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { Account } from '@mitel/cloudlink-sdk';
import { AccountsService } from '../../services/accounts.service';
import { OrdersService, OrderItem } from '../../services/orders.service';
import { SpinnerService } from '../../services/spinner.service';
import { ParentCommsService } from '../../services/parent-comms.service';
import { AppStateService } from '../../services/app-state.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})

export class OrderDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<OrderDetailsComponent>,
    public translateSvc: TranslateService,
    private orderService: OrdersService, //use it before account service because it has 
    private accountService: AccountsService,  //auth service as dependency which will initiate auth for all api's in adk.

    private spinnerService: SpinnerService,
    private parentCommsService: ParentCommsService,
    private appStateService: AppStateService
  ) 
    {

      this.order = data.row;
    }

  orderFormGroup: UntypedFormGroup;
  assignSection: UntypedFormGroup;
  accounts: Account[] = [];
  selectedAccount: Account = undefined;
  order: OrderItem = undefined;
  disableAssignButton: boolean = false;

  showFormError = false;
  errMsg = '';
  errMsgStyle: any = { 'max-width': '500px' };

  displaySpinner = true;

  nameTooltip: string = '';
  customerPOTooltip: string = '';
  resellerPOTooltip: string = '';
  resellerSapIdTooltip: string = '';
  customerSapIdTooltip: string = '';
  trackingNumberTooltip: string = '';
  sapOrderIdTooltip: string = '';

  showDetails = false;

  restrictedAccess: boolean = true;

// close panel on esc key
@HostListener('document:keyup.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
      this.onClose(event);
}

ngOnInit() {
  this.displaySpinner = true;
  this.initForm();
}

ngAfterViewInit() {
  setTimeout( () => {
    const nameElement = document.getElementById('accountNameLabel');
    if ( nameElement && nameElement.offsetWidth < nameElement.scrollWidth )
      this.nameTooltip = this.order.accountName;
  });
}
initForm() {
  this.orderFormGroup = new UntypedFormGroup({
    assignSection: new UntypedFormGroup({
      account: new UntypedFormControl(''),
      accountFilter: new UntypedFormControl('')
    })
  });

  this.assignSection = <UntypedFormGroup>(
    this.orderFormGroup.controls.assignSection
  );
  this.enableOrDisablePrimaryButton();
  this.restrictedAccess = this.appStateService.getRestrictedAccess();
}

enableOrDisablePrimaryButton() {
    if ( this.selectedAccount ) {
      this.disableAssignButton = false;
    }
    else{
      this.disableAssignButton = true;
    }
  }

  onClose(event) {

    this.dialogRef.close();
    this.showFormError = false;
    this.spinnerService.hideSpinner();
  }

  handleSelectedAccount( event )
  {
    this.selectedAccount = event;
    this.enableOrDisablePrimaryButton();
  }

  onAssignOrder(event)
  {
    this.errMsg = '';
    this.showFormError = false;
    this.spinnerService.setSpinnerMessageByKey('order_details.assigning_account_to_order');
    this.spinnerService.showSpinner();

    this.orderService.assignOrderToAccount(this.order, this.selectedAccount.accountNumber).then( () => {
      
      if ( this.parentCommsService.getCurrentAccountIdToAssignOrder() 
            && this.parentCommsService.getCurrentAccountIdToAssignOrder() === this.selectedAccount.accountId) {
              this.dialogRef.close({ complete: true });
              this.parentCommsService.tellParentToNavigateToOriginalPage();
              //this.parentCommsService.tellParentToRedirectToAccountDetails();
      } else {
        this.parentCommsService.setCurrentAccountIdToAssignOrder(undefined);
        // in this case we want to let the caller know that we have assigned to a specific account so it can do further processing
        this.dialogRef.close({ complete: true, account: this.selectedAccount });
      }
      this.spinnerService.hideSpinner();
    })
    .catch(err => {
      console.error("Error assigning orders", err);
      this.errMsg = this.createErrorMessage(err);
      this.showFormError = true;
      this.spinnerService.hideSpinner();
    });
  }

  createErrorMessage(err): string
  {
    let msg = this.translateSvc.instant('order_details.error_assigning_order');

    // This commented code is for future considerations once the message format has been solidified
    if ( err && err.body && err.body.message )
    {
    //   let errorNum = undefined;
    //   let parts = err.body.message.split("::");
    //   if ( parts && parts.length >= 2 )
    //     errorNum = parts[1];

    //   if ( errorNum == "0001" ) // going to extract number to use
    //   {
    //     let nums =  err.body.message.split("(");
    //     let specError =  this.translateSvc.instant('order_details.' + errorNum, {free:nums[1], paid:nums[3]});
    //     msg = msg + " " + specError;
    //   }
    //   else
    //   {
    //     msg = msg + " " + err.body.message;
    //   }

      msg = msg + " " + err.body.message;
    }
    else // not a billing micro error
      msg = msg + " " + err;

    return msg;
  }

  onCreateNewAccount(event)
  {
    if ( this.parentCommsService.tellParentToCreateNewAccount() )
    {
      this.onClose({});
    }
    else{
      this.errMsg = this.translateSvc.instant('order_details.cannot_comm_parent');
      this.showFormError = true;
      this.spinnerService.hideSpinner();
    }
  }

  handleShowDetailsEvent()
  {
    this.showDetails = !this.showDetails;

    if ( this.showDetails )
    {
      setTimeout( () => { 
        const cpoElement = document.getElementById('customerPONumber');
        if ( cpoElement && cpoElement.offsetWidth < cpoElement.scrollWidth )
          this.customerPOTooltip = this.order.customerPoNumber;
        
          const tNumElement = document.getElementById('trackingNumber');
        if (tNumElement && tNumElement.offsetWidth < tNumElement.scrollWidth )
          this.trackingNumberTooltip = this.order.orderNumber;
    
        const rpoElement = document.getElementById('resellerPONumber');
        if ( rpoElement && rpoElement.offsetWidth < rpoElement.scrollWidth )
          this.resellerPOTooltip = this.order.poNumber;

        const rSapIdElement = document.getElementById('resellerSapId');
        if ( rSapIdElement && rSapIdElement.offsetWidth < rSapIdElement.scrollWidth )
          this.resellerSapIdTooltip = this.order.resellerSapId;
        
        const cSapIdElement = document.getElementById('customerSapId');
        if ( cSapIdElement && cSapIdElement.offsetWidth < cSapIdElement.scrollWidth )
          this.customerSapIdTooltip = this.order.customerSapId;
        
        const sapOrderIdElement = document.getElementById('sapOrderId');
        if ( sapOrderIdElement && sapOrderIdElement.offsetWidth < sapOrderIdElement.scrollWidth )
          this.sapOrderIdTooltip = this.order.sapOrderId;

        const closeLinkElement = document.getElementById("closeLink");
        if ( closeLinkElement )
          closeLinkElement.focus();
      });
    }
    else
    {
      setTimeout( () => { 
        const seeLinkElement = document.getElementById("seeLink");
        if ( seeLinkElement )
          seeLinkElement.focus();
      });
    }
  }

  ngOnDestroy() {
    this.showFormError = false;
    this.spinnerService.hideSpinner();
  }
}
