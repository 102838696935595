import { Injectable } from '@angular/core';
import {Observable, Subject, of, Subscription} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppInfoService {
    private appSubscription: Subscription;

  constructor() { }
  supportedLanguages = [
    { name: 'English (US)', langCode: 'en', displayAbbr: 'EN (US)' },
    { name: 'English (GB)', langCode: 'en-GB', displayAbbr: 'EN (GB)' },
    { name: 'French', langCode: 'fr', displayAbbr: 'FR' },
    { name: 'German', langCode: 'de', displayAbbr: 'DE' },
    { name: 'Spanish', langCode: 'es', displayAbbr: 'ES' },
    { name: 'Italian', langCode: 'it', displayAbbr: 'IT' },
    { name: 'Dutch', langCode: 'nl', displayAbbr: 'NL' },
    { name: 'Portuguese (Portugal)', langCode: 'pt', displayAbbr: 'PT' }
  ];

  getSupportedLanguages(): any[] {
      return (this.supportedLanguages);
  }

  getLangCodes(): string[] {
    let langCodes: string[] = [];
    for (const lang of this.supportedLanguages) {
        langCodes.push(lang.langCode);
    }
    return langCodes;
  }
}
