import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RBACAuthDirective } from './rbac/directives/rbac-auth.directive';

import {
  ClConsoleComponentsModule,
  ClHeaderComponent,
  ClLogService
} from '@mitel/cloudlink-console-components';
import { RouteGuardService } from './services/route-guard.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OrdersListPageComponent } from './components/orders-list-page/orders-list-page.component';
import { OrdersListComponent } from './components/orders-list-page/orders-list/orders-list.component';
import { OrderDetailsComponent }  from './components/order-details/order-details.component';
import { OrderCompleteComponent }  from './components/order-complete/order-complete.component';
import { AppUserClaimsService } from './services/app-user-claims.service';
import { OrdersService } from './services/orders.service';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { OrderLicensesComponent }  from './components/order-details/order-licenses/order-licenses.component';
import { SelectAccountComponent }  from './components/order-details/select-account/select-account.component';
import { SpinnerService } from './services/spinner.service';
import { FormErrorAlertComponent } from './components/shared/form-error-alert/form-error-alert.component';
import { ErrorsHandlerService } from './services/errors-handler.service';
import { AppInfoService } from './services/app-info.service';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { AppStateService } from './services/app-state.service';
import { UserPermissionsService } from './services/user-permissions.service';

@NgModule({
    declarations: [
        AppComponent,
        OrdersListPageComponent,
        OrdersListComponent,
        SpinnerComponent,
        OrderDetailsComponent,
        OrderCompleteComponent,
        OrderLicensesComponent,
        SelectAccountComponent,
        FormErrorAlertComponent,
        RBACAuthDirective
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        AppRoutingModule,
        ClConsoleComponentsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatSelectModule,
        MatIconModule,
        MatChipsModule,
        MatButtonModule,
        MatDialogModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatCardModule,
        MatGridListModule,
        MatAutocompleteModule,
        NgxMatSelectSearchModule
    ],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {subscriptSizing: 'dynamic'}},
        RouteGuardService,
        AppUserClaimsService,
        ClHeaderComponent,
        OrdersService,
        ClLogService,
        SpinnerService,
        ErrorsHandlerService,
        AppInfoService,
        AppStateService,
        UserPermissionsService
    ],
    exports: [
      RBACAuthDirective
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
