import { Injectable } from '@angular/core';
import { Account } from '@mitel/cloudlink-sdk';
import { AdminService } from './admin.service';
import { ErrorsHandlerService } from './errors-handler.service';

@Injectable({
	providedIn: 'root'
})
export class AccountsService {

	private _accounts: Account[] = [];

	set accounts(value: Account[]) {
		this._accounts = value;
	}

	get accounts(): Account[] {
		return this._accounts;
	}

	constructor(
		private adminService: AdminService,
		private errService: ErrorsHandlerService
	) {}

	setAccounts(accounts: Account[]) {
		this.accounts = accounts;
	}

	getAccounts(): Promise<Account[]> {
		return this.adminService.getAllAccounts().then(accounts => {
			this.accounts = accounts;
			return accounts;
		})
		.catch(err => {
			this.errService.handleError(err);
			throw err;
		});
	}

	getAllActiveAccounts(): Promise<Account[]> {
		return this.adminService.getAllAccounts().then(accounts => {
			this.accounts = accounts;
			let activeAccounts = accounts.filter( account => {
				return account.active == true;
			});
			return activeAccounts;
		})
		.catch(err => {
			this.errService.handleError(err);
			throw err;
		});
	}

	async getAccountById(accountId: string): Promise<Account> {
		let elIndex = this.accounts.findIndex((account) => account.accountId === accountId);
		if (elIndex !== -1) {
			return Promise.resolve(this.accounts[elIndex]);
		} else {
			let account = await this.adminService.getAccount(accountId);
			this.accounts.push(account);
			return Promise.resolve(account);
		}
	}
}
