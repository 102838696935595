  	<div class="ipad-width full-dialog-properties">
        <div class="title">
            <div *ngIf="showFormError">
                <form-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></form-error-alert>
            </div>
            <div class="float-right-child">
                <button mat-icon-button id="confirm-close" attr.aria-label="{{ 'app.close_button' | translate }}" (click)="onClose($event)"><span aria-hidden="true"> &times;</span>
                    </button> 
            </div>
            <div class="center-child">
                <img class="check-icon" src="../assets/images/check-circ-solid.svg" />
            </div>
        </div>
    </div>
    <div class="ipad-width order-info-container bottom-spacing">
        <div class="orderAddedlabel">
            <label>{{ 'order_complete.order-added' | translate }}</label>
        </div>
        <div class="instructionslabel">
            <label>{{ 'order_complete.enable-integration' | translate }}</label>
        </div>
        <div class="button-div">
            <button 
                mat-button 
                class="btn btn-default" 
                attr.aria-label="{{ 'order_complete.go-to-account-integrations' | translate }}" 
                (click)="onForwardToAccount($event)">
                    {{ 'order_complete.go-to-account-integrations' | translate }}
                </button>
        </div>
    </div>
  
    <div class="rectangle-copy"></div>
    <div class="actions-spacing">
        <div class="float-right">
            <button 
                mat-button 
                class="btn btn-default cancel" 
                attr.aria-label="{{ 'app.close' | translate }}" 
                (click)="onClose($event)">{{ 'app.close_button' | translate }}
            </button>
        </div>
    </div>
