import {Component, OnDestroy, OnInit} from '@angular/core';
import {SpinnerService} from '../../../services/spinner.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {

  isShown = false;
  subscription: Subscription;
	spinnerMessageKey: string;

	constructor(
    private spinnerService: SpinnerService
  ) { }

  ngOnInit() {
	  this.isShown = this.spinnerService.isSpinnerShown();

	  this.spinnerMessageKey = this.spinnerService.getSpinnerMessageKey();
	  this.subscription = this.spinnerService.spinnerStatusChanged.subscribe((status) => {
	    this.isShown = status;
    });
	  this.subscription.add(this.spinnerService.spinnerMessageKeyChanged.subscribe((key) => {
		  this.spinnerMessageKey = key;
	  }));
  }

	ngOnDestroy(): void {
	  this.subscription.unsubscribe();
	}
}
