  	<div class="ipad-width full-dialog-properties">
        <div  class="title">
            <div class="center-child" *ngIf="this.order.expiring">
                <label class="expiring-order-badge">{{'order_details.order_expiry_time' | translate: {numdays: this.order.expiryDays} }}</label>
            </div>
            <div class="center-child" *ngIf="this.order.expired">
                <label class="expired-order-badge">{{'orders_list.expired' | translate}}</label>
            </div>
            <div class="center-child" *ngIf="this.order.isPending">
                <label class="pending-order-badge">{{'orders_list.pending_activation' | translate}}</label>
            </div>
            <div class="center-child" *ngIf="this.order.perpetual">
                <label class="perpetual-order-badge">{{'orders_list.perpetual' | translate}}</label>
            </div>
            <div class="float-right-child">
             <button mat-icon-button id="confirm-close" attr.aria-label="{{ 'app.close_button' | translate }}" (click)="onClose($event)"><span aria-hidden="true"> &times;</span>
                </button> 
            </div>
        </div>

        <div *ngIf="showFormError">
            <form-error-alert [errorMessage]="errMsg" [externalStyles]="errMsgStyle"></form-error-alert>
        </div>
        <div class="files-icon-section">
            <img class="files-icon" src="../assets/images/files.svg" />
        </div>
    
    <div class="ipad-width order-info-container">
        <div class="subscriptionNumberlabel">
            <label>{{ 'order_details.subscription_number' | translate }}:&nbsp;{{order.subscriptionNumber}}</label>
        </div>
        <div *ngIf="showDetails==false">
            <label 
                id="seeLink"
                class="details-link clickable-pointer" 
                (click)="handleShowDetailsEvent()" 
                tabindex="0" 
                (keydown.enter)="$event.preventDefault(); handleShowDetailsEvent()">
                {{ 'order_details.see_additional_details' | translate }}
                <img src="../assets/images/arrow-down.png" class="Arrow-Dropdown clickable-pointer">
            </label>
            
        </div>

        <div *ngIf="showDetails==true">
            <div>
                <label 
                    id="closeLink"
                    class="details-link clickable-pointer" 
                    (click)="handleShowDetailsEvent()" 
                    tabindex="0"
                    (keydown.enter)="$event.preventDefault(); handleShowDetailsEvent()">
                    {{ 'order_details.hide_additional_details' | translate }}
                    <img src="../assets/images/arrow-up.png" class="Arrow-Dropdown clickable-pointer">
                </label>
                
            </div>
            <div class="detail-whole-line" [matTooltip]="resellerPOTooltip">
                <label class="order-detail-label">{{ 'order_details.po_number' | translate }}:&nbsp;</label>
                <label id="resellerPONumber" class="poNumberlabel">{{order.poNumber}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="resellerSapIdTooltip">
                <label class="order-detail-label">{{ 'order_details.reseller_SAP_Id' | translate }}:&nbsp;</label>
                <label id="resellerSapId" class="poNumberlabel">{{order.resellerSapId}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="trackingNumberTooltip">
                <label class="order-detail-label">{{ 'order_details.tracking_number' | translate }}:&nbsp;</label>
                <label id="trackingNumber" class="poNumberlabel">{{order.orderNumber}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="customerPOTooltip">
                <label class="order-detail-label" >{{ 'order_details.customerPONumber' | translate }}:&nbsp;</label>
                <label id="customerPONumber" class="poNumberlabel" >{{order.customerPoNumber}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="customerSapIdTooltip"> 
                <label class="order-detail-label">{{ 'order_details.customerSapId' | translate }}:&nbsp;</label>
                <label id="customerSapId" class="poNumberlabel">{{order.customerSapId}}</label>
            </div>
            <div class="detail-whole-line" [matTooltip]="sapOrderIdTooltip"> 
                <label class="order-detail-label">{{ 'order_details.sapOrderId' | translate }}:&nbsp;</label>
                <label id="sapOrderId" class="poNumberlabel">{{order.sapOrderId}}</label>
            </div>
            <div class="rectangle-copy-title"></div>
        </div>

        <div class="order-details-label">
            <label>{{ 'order_details.start_date' | translate }}:&nbsp;{{order.orderDate| date:'YYYY-MM-dd'}}</label>
            <label class="float-right poNumberlabel">{{ 'order_details.term' | translate }}:&nbsp;{{order.term}}</label>
        </div>
        <div class="whole-line" [matTooltip]="nameTooltip">
            <label class="order-detail-label">{{ 'order_details.order_assigned_to' | translate }}:&nbsp;</label>
            <label class="accountLabel" *ngIf="order.unassigned">{{ ('orders_list.unassigned' | translate).toLowerCase()}}</label>
            <label 
                id="accountNameLabel" 
                class="accountLabel" 
                *ngIf="order.unassigned==false">
                {{order.accountName}}
            </label>
        </div>
    </div>
    <div class="rectangle-copy-title"></div>

    <div class="ipad-width">
        <div class="bottom-spacing">
            <app-order-licenses [orderDetails]="order"></app-order-licenses>
        </div>
        <div *ngIf="this.order.unassigned && this.order.expired == false" class="rectangle-copy"></div>
        <div *ngIf="this.order.unassigned && this.order.expired == false">
                <app-select-account 
                    [accountFormGroup]="assignSection" 
                    [excludeAccount]="this.order.accountId" 
                    (selectedAccountChanged)="this.handleSelectedAccount($event)">
                </app-select-account>
        </div>
    </div>
    <div class="button-divider"></div>
    <div  *ngIf="this.order.unassigned == false || this.order.expired == true" >
        <div class="float-right">
            <button mat-button class="btn btn-default cancel" attr.aria-label="{{ 'app.close' | translate }}" (click)="onClose($event)">{{ 'app.close_button' | translate }}</button>
        </div>
    </div>
   
    <div *ngIf="this.order.unassigned && this.order.expired == false">
            <button mat-button
                app-rbac category="accounts" [actions]="['add']" [mode]="restrictedAccess ? 'readonly' : null"
                class="btn btn-default" 
                [disabled]="restrictedAccess"
                attr.aria-label="{{ 'order_details.create_new_account' | translate }}" 
                (click)="onCreateNewAccount($event)">
                    {{ 'order_details.create_new_account' | translate }}
            </button>
    
            <div class="float-right">
                <button mat-button class="btn btn-default cancel" attr.aria-label="{{ 'app.close' | translate }}" (click)="onClose($event)">{{ 'app.close_button' | translate }}</button>
                <button 
                    mat-flat-button
                    class="btn"
                    [disabled]="disableAssignButton || restrictedAccess"
                    color=primary 
                    attr.aria-label="{{ 'order_details.assign_button' | translate }}" 
                    (click)="onAssignOrder($event)" 
                    id="assign-order-button">
                        {{ 'order_details.assign_button' | translate }}
                </button>
            </div>
    </div>
</div>