import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

    constructor(
        private authSvc: AuthService
    ) {}
  
    /*
    Err on the side of caution. 
    Assume the ui is restricted. 
    If the policy test is successful and not ExplicitAllow, then set restricted to false.
    Explicit Allow means the user does have the policy to restrict.
    */
    async checkForSupportUiRestrictions(): Promise<boolean> {
        let restricted = true;
        let policyRequest = {
          resources: [{
            resource: 'https://mitel.io/auth/supportportal/features/readonly-ui',
            action: '*'
          }]
        };	
        try {
          let response = await this.authSvc.testPolicy(policyRequest);
          if(response && response.results){
            let result = response.results[0];
            if (result.effect && result.effect != 'ExplicitAllow') {
              restricted = false;
            }
          }
        } catch (err) {
          console.error('Policy test error:', err);
        }
        return restricted;
    }
}