import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppUserClaimsService } from '../../../services/app-user-claims.service';
import { OrderDetailsComponent } from '../../order-details/order-details.component';
import { OrderCompleteComponent } from '../../order-complete/order-complete.component';
import { SpinnerService } from '../../../services/spinner.service';
import { OrdersService, OrderItem, IntegToLicenseCrossreference } from '../../../services/orders.service'; 
import { UserClaims } from '@mitel/cloudlink-sdk';
import { ParentCommsService } from 'src/app/services/parent-comms.service';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})

export class OrdersListComponent implements OnInit {
	displayedColumns: string[] = ['sapOrderId', 'subscriptionNumber', 'resellerPONumber', 'customerPONumber', 'accountName', 'subscriptionExpiry'];
	dataSource: MatTableDataSource<OrderItem>;
	orders: OrderItem[] = [];
	subscription: Subscription;
	claims: UserClaims;

	showError = false;
  	errMsg = '';
  	errMsgStyle: any = { 'max-width': '100%' };

	 @ViewChild(MatSort, {static: true}) sort: MatSort;

	filters = [
		{ name: 'All', property: '', value: '' },
		{ name: 'Unassigned', property: 'unassigned', value: true },
		{ name: 'Expiring', property: 'expiring', value: true }
	];
	currentFilter = this.filters[0];
	
	constructor(
	  public dialog: MatDialog,
	  private ordersService: OrdersService,
	  private matIconRegistry: MatIconRegistry,
	  private domSanitizer: DomSanitizer,
	  private spinnerService: SpinnerService,
	  private appUserClaimsSvc: AppUserClaimsService,
	  public translateSvc: TranslateService,
	  private parentCommsService: ParentCommsService,
	  private adminService: AdminService
	) {
		this.matIconRegistry.addSvgIcon(
			'search_app',
			this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/search-big.svg")
		);
	}


  ngOnInit() {
	  this.spinnerService.showSpinner();
	  this.errMsg = '';
	  this.showError = false;
	  this.dataSource = new MatTableDataSource(this.processData([]));
	  if( this.parentCommsService.getCurrentAccountIdToAssignOrder()) {
		  this.currentFilter = this.filters[1];
	  }
	  // Do not want to block the loading of the orders list page
	  setTimeout( ()=> {
		this.populateOrdersList();
	  }, 100);
	}

	isSpinnerShown():boolean {
		return this.spinnerService.isSpinnerShown();
	}

	private populateOrdersList()
	{
		this.subscription = this.appUserClaimsSvc.claimsChanged.subscribe( claims => {
			this.claims = claims;
			this.requestOrdersList();
		});
	}

	customSearch( data: OrderItem, filter: string )
	{
		if (data.subscriptionNumber.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.accountName.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.orderDate.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			(data.orderExpiry && data.orderExpiry.toLowerCase().indexOf(filter.toLowerCase()) != -1 ) ||
			data.sapOrderId.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.poNumber.toLowerCase().indexOf(filter.toLowerCase()) != -1 ||
			data.customerPoNumber.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
		{
			return true;
		}
		else 
		{
			if ( data.unassigned )
			{
			 	let label = this.translateSvc.instant('orders_list.unassigned');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.expired )
			{
				let label = this.translateSvc.instant('orders_list.expired');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.expiring )
			{
				let label = this.translateSvc.instant('orders_list.expiring_soon');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.isPending )
			{
				let label = this.translateSvc.instant('orders_list.pending_activation');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}
			if ( data.perpetual )
			{
				let label = this.translateSvc.instant('orders_list.perpetual');
				if ( label.toLowerCase().indexOf(filter.toLowerCase()) != -1 )
					return true;
			}

			let unavailableLabel = this.translateSvc.instant('orders_list.unavailable');
			if (unavailableLabel.toLowerCase().indexOf(filter.toLowerCase()) != -1 && 
				(!data.sapOrderId  || !data.poNumber || !data.customerPoNumber)){
					return true;
			}
		}
		return false;
	};

	private requestOrdersList()
	{
		if ( !this.claims )
			return;

		this.ordersService.getAllOrders( this.claims.accountId ).then( orders => {
			this.orders = orders;
			this.dataSource = new MatTableDataSource(this.processData(orders));
			this.setFilterPredicateAndSorting();
			this.spinnerService.hideSpinner();
		})
		.catch(err => {
			console.error("Error retrieving orders", err); //TBD - how to handle the error
			this.spinnerService.hideSpinner();
			if ( err.statusCode == 400 && 
				(err.body && err.body.message && err.body.message.indexOf("is no billing account")) > -1 )
			{
				console.log("This is not an error, this account does not have any orders");
				this.dataSource = new MatTableDataSource([]);
			} 
			else
			{
				this.errMsg = this.translateSvc.instant('orders_list.error_retrieving_orders');
				if ( err.body && err.body.message )
					this.errMsg += " " + err.body.message;

				this.showError = true;
			}
		});
	}

	async onEditOrder(row: OrderItem) {

		const dialogRef = this.dialog.open(OrderDetailsComponent, {
			autoFocus: false,
			data: {
				row: row,
				ordersList: this.orders
			}
		});

		dialogRef.afterClosed().subscribe( async result => {

			if ( result && result.complete == true && result.account == undefined ) //update the list because a change occurred
			{
				// go right to the update
				this.spinnerService.setSpinnerMessageByKey('app.loading');
				this.spinnerService.showSpinner();
				this.requestOrdersList();
			}
			// determine if there was an order assigned
			// if so, get the selected account from the result
			// check the account tags. Is there a corresponding integration for that license?
			// if so, continue on, if not, show the dialog to indicate they need an integration
			else if ( result && result.complete == true && result.account !== undefined ) 
			{
				// we need to check to see if the specific account has the integration required 
				// for the assigned license
				let tags = undefined;
				let hasIntegration = false;
				try{
					tags = await this.adminService.getAccountTags(result.account.accountId);
				}
				catch (err)
				{
					console.error("there was an issue requesting tags for accouunt. Will assume no tags", result.account.accountId, err);
				}
				hasIntegration = this.determineIfAccountHasProperIntegrationForOrder(tags, row);

				if ( !hasIntegration )
				{
					const dialogRef2 = this.dialog.open(OrderCompleteComponent, { data: {accountId: result.account.accountId}} );
					dialogRef2.afterClosed().subscribe(result => {
						//update the landing list
						this.spinnerService.setSpinnerMessageByKey('app.loading');
						this.spinnerService.showSpinner();
						this.requestOrdersList();
					});
				}
				else
				{
					//update the landing list
					this.spinnerService.setSpinnerMessageByKey('app.loading');
					this.spinnerService.showSpinner();
					this.requestOrdersList();
				}
			}
		});
	}

	determineIfAccountHasProperIntegrationForOrder( tags: any, order: OrderItem ): boolean
	{
		if ( tags == undefined || tags.products == undefined || tags.products.length == 0 )
			return false;

		if ( !order )
			return true;
		
		if ( !order.licenses || order.licenses.length == 0 )
			return true;

		for ( var i = 0; i < order.licenses.length; i++ )
		{
			// first non match means we return false
			// not specific about the type of license
			let product = this.findProductForLicense(order.licenses[i].productId);
			if ( product != undefined )
			{
				if ( tags.products.indexOf(product) == -1  )
					return false;
			}
		}

		return true;
	}

	findProductForLicense( productId: string ): string
	{
		for ( var i = 0; i < IntegToLicenseCrossreference.length; i++ )
		{
			for ( var j = 0; j < IntegToLicenseCrossreference[i].licenseIdInBilling.length; j++ )
			{
				if ( IntegToLicenseCrossreference[i].licenseIdInBilling[j] == productId )
					return IntegToLicenseCrossreference[i].integProductCode;
			}
		}
		return undefined;
	}
	setCurrentFilter(event) {
		let currentFilter = this.dataSource.filter;
		this.dataSource = new MatTableDataSource(this.processData(this.orders));
		this.setFilterPredicateAndSorting();
		if (currentFilter) {
			this.onSearch(currentFilter);
		}
	}

	setFilterPredicateAndSorting() {
		this.dataSource.filterPredicate = this.customSearch.bind(this);
		this.dataSource.sortingDataAccessor = (item, property) => {
			switch (property) {
			   case 'subscriptionExpiry': return new Date(item.orderExpiry);
			   case 'resellerPONumber': return item['poNumber'];
			   case 'customerPONumber': return item['customerPoNumber'];
			   default: return item[property];
			}
		};
		this.dataSource.sort = this.sort;
	}

	onSearch(filterValue: any) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	private processData(orders: OrderItem[]): OrderItem[] {
		if (orders.length > 0) {
			return orders
				.filter((order) => {
					if (this.currentFilter.name === 'All') {
						return true;
					} else if (this.currentFilter.name === 'Unassigned') {
						return order.unassigned === this.currentFilter.value;
					} else if (this.currentFilter.name === 'Expiring') {
						return order.expiring === this.currentFilter.value;
					}
				})
				// .map(account => {
				// 	return {
				// 		name: account.name,
				// 		createdOn: account.createdOn,
				// 		modifiedOn: account.modifiedOn,
				// 		status: account.active,
				// 		accountNumber: account.accountNumber || '',
				// 		id: account.accountId
				// 	};
				// });
		} else {
			return [];
		}
	}

	ngOnDestroy()
	{
		this.subscription.unsubscribe();
	}
}
