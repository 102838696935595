import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderItem } from '../../../services/orders.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-order-licenses',
  templateUrl: './order-licenses.component.html',
  styleUrls: ['./order-licenses.component.scss']
})

export class OrderLicensesComponent implements OnInit, AfterViewInit{
  @Input() orderDetails: OrderItem;

  expandedOrderDetails:any = [];

  constructor(    
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) 
    {
      this.matIconRegistry.addSvgIcon(
        'info_icon',
        this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/info-solid.svg")
      );
    }

  ngOnInit()
  {
    this.expandedOrderDetails = Object.assign([], this.orderDetails.licenses);
  }

  ngAfterViewInit() {
    setTimeout( () => {
      for( var i = 0; i < this.expandedOrderDetails.length; i++ )
      {
        this.expandedOrderDetails[i].tooltip = undefined;
        const element = document.getElementById('proDetails'+i);
        if ( element && element.offsetWidth < element.scrollWidth )
          this.expandedOrderDetails[i].tooltip = this.expandedOrderDetails[i].productDetails;
      }
    });
  }

}
