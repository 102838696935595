import { Injectable } from '@angular/core';

import { User, AccessPolicy, PolicyCollection } from '@mitel/cloudlink-sdk/admin';
import { AdminService as CloudAdmin, Config, Odata, Utils } from '@mitel/cloudlink-sdk';

import { ErrorsHandlerService } from '../../services/errors-handler.service';
import { UserRoles, BaseRoleType, Permission, rbacPolicyConst, rbacUtil } from '../utils/rbac-auth.util';

import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class RBACAuthService {
  private readonly api: CloudAdmin;

  permissions: Permission[] = [];
  public validPermissionsFlag: Boolean = false;
  public validPermissionsHandled = new Subject<Boolean>();

  constructor(
    private errorHandlerService: ErrorsHandlerService
  ) {
    Config.cloud = environment.cloud;
    this.api = new CloudAdmin();
  }

  setPermissions(resourcePermissions: string[]) {
    this.permissions = [];
    if (resourcePermissions) {
      resourcePermissions.forEach(permission => {
        try {
          let paths = permission.replace(rbacPolicyConst.actionFeature, '').split('/');
          if (paths.length > 2) {
            this.permissions.push({ role: paths[0], category: paths[1], action: paths[2], resource: permission });
          }
        }
        catch {
          console.error(`Error while reading the permission: ${permission}`);
        }
      });
    }
  }

  public getUser(userId: string, expand?: string, options?: any): Promise<User> {
    return new Promise<User>(async (resolve, reject) => {
      try {
        /* istanbul ignore next */
        let params = expand ? (options ? { userId, $Expand: expand, options: options } : { userId, $Expand: expand }) :
          (options ? { userId, $Expand: expand, options: options } : { userId });
        let user = await this.api.getUser(params);
        await this.setRBACPermissionsByUser(user)
        resolve(user);
      } catch (err) {
        console.error(`Error ocurred while reading the user`);
        reject(err);
      }
    });
  }

  async setRBACPermissionsByUser(user: User) {
    if (user.role === UserRoles.PARTNER_ADMIN || user.role === UserRoles.ACCOUNT_ADMIN) {
      let rbacUserTag = rbacUtil.getTagPropertyById(user, rbacPolicyConst.rbacUserTagName, rbacPolicyConst.rbacUserTagId);
      if (rbacUserTag) {
        await this.getPolicy(user.accountId, rbacUserTag).then(policy => {
          if (policy.statements && policy.statements.length > 0) {
            let baseRoleTypes = Object.keys(BaseRoleType).map(baseRoleType => BaseRoleType[baseRoleType]);
            policy.statements.forEach(statement => {
              if (statement.statementId && baseRoleTypes.includes(statement.statementId.toLocaleUpperCase())) {
                this.setPermissions(statement.resource);
              }
            });
          }
        })
        .catch(err => {
          console.error(`Error ocurred while reading the policy for account ${user.accountId}, policyId ${rbacUserTag}, error ${err}`);
        });
      }
      this.setValidPermissionsFlag();
    }
  }

  setValidPermissionsFlag() {
    this.validPermissionsFlag = true;
    this.validPermissionsHandled.next(this.validPermissionsFlag);
  }

  isPermissionDeniedForUser(category: string, action: string): boolean {
    let isDenied = false;
    if (this.permissions && this.permissions.length > 0) {
      isDenied = Boolean(this.permissions.find(permission => {
        return rbacUtil.compareStringsCaseInsensitive(category, permission.category) &&
          rbacUtil.compareStringsCaseInsensitive(action, permission.action)
      }));
    }
    return isDenied;
  }

  getPermissions() {
    return this.permissions;
  }

  getPolicy(accountId: string, policyId: string): Promise<AccessPolicy> {
    const params = {
      accountId,
      policyId
    };
    return this.api.getAccountAccessPolicy(params);
  }

  getAllAccountPolicies(accountId: string, odata?: Odata): Promise<AccessPolicy[]> {
    return new Promise<AccessPolicy[]>(async (resolve, reject) => {
      let policies: AccessPolicy[] = [];
      try {
        console.log(`Function: getAllAccountPolicies, odata: ${odata}`);
        const policyCollection = await this.getAccountPolicies(accountId, odata);
        if (policyCollection && policyCollection.count > 0 && policyCollection._embedded && policyCollection._embedded.items) {
          policies = policyCollection._embedded.items;
          const next = Utils.getOdataNext(policyCollection);
          if (next) {
            const morePolicies = await this.getAllAccountPolicies(accountId, next);
            if (morePolicies) {
              policies = policies.concat(morePolicies);
            }
          }
        }
        resolve(policies);
      } catch (err) {
        this.errorHandlerService.handleError(err);
        reject(err);
      }
    });
  }

  getAccountPolicies(accountId: string, odata?: Odata, options?: any): Promise<PolicyCollection> {
    return this.api.getAccountAccessPolicies({ accountId, options });
  }

}
