<div class="account-container">
    <div class="instructions">{{'order_details.please_pick_acct_msg' | translate}}
    </div>
    <div [ngStyle]="{'display': !loading ? 'block' : 'none'}">
        
        <mat-form-field tabindex="0" class="form-input">
            <mat-label>{{'order_details.company' | translate}}</mat-label>
            <mat-select 
                [formControl]="accountFormGroup.controls['account']"
                (selectionChange)="onSelectAccount()"
                data-e2e="choose-account-select"
                #singleSelect>

                <mat-option>
                    <ngx-mat-select-search class="mat-select-search-inside-mat-option"
                        name="accountFilter" 
                        ngDefaultControl 
                        tabindex="0"
                        [ariaLabel]="'order_details.search_companies' | translate" 
                        [noEntriesFoundLabel]="'order_details.no_results_found' | translate"
                        [placeholderLabel]="'order_details.search_companies' | translate"
                        [formControl]="accountFormGroup.controls['accountFilter']">
                        <div class="mat-select-search-custom-header-content search-icon">               
                            <img src="../assets/images/search-big.svg"/> 
                        </div>
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let account of filteredAccounts | async" [value]="account">
                    <div class="option-info" [ngClass]="{'sapId': account.sapId}">
                        <span class="option-name">{{account.name}}</span>
                        <span class="option-sapId" *ngIf="account.sapId">{{account.sapId}}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="restrictedAccess" class="restricted-warning">{{'order_details.restricted_view_warning' | translate}}
    </div>
    <div class="spinner-state" *ngIf="loading">
        <img *ngIf="loading" src="../../../assets/images/Spinner.png" class="licenses-loading animate-spinner">
    </div>  
</div>
