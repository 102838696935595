import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParentCommsService {

  private currentAccountId; // It stores the accountId to whom an order is required to be assigned.
  private returnToOrigin; // the page that the operation should return to

  constructor() { }

  tellParentToCheckSession() {
    if (this.isInsideIframe()) {
      window.parent.postMessage("CL_CHECK_SESSION", '*');
      return true;
    }
    return false;
  }

  tellParentToRedirectToLogin() {
    if (this.isInsideIframe()) {
      window.parent.postMessage("CL_REDIRECT_LOGIN", '*');
      return true;
    }
    return false;
  }

  tellParentToCreateNewAccount() {
    const message = {
      type: 'CL_NAVIGATION',
      url: '/newaccount'
    };

    if (this.isInsideIframe()) {
      window.parent.postMessage(message, '*');
      return true;
    }
    return false;
  }

  tellParentToNavigatToAccountIntegrations( accountId: string) 
  {
      const message = {
        type: 'CL_NAVIGATION',
        url: `/accounts/${accountId}`
      };

      if (this.isInsideIframe()) {
        window.parent.postMessage(message, '*');
        return true;
      }
      return false;
  }

  tellParentToNavigateToOriginalPage() 
  {
      const message = {
        type: 'CL_NAVIGATION',
        url: ' '
      };

      if (this.isInsideIframe()) {
        message.url = "/" + this.returnToOrigin + "/" + this.currentAccountId;
        window.parent.postMessage(message, '*');
        return true;
      }
      return false;
  }

  // the following method should be converted to the new navigation method
  tellParentToRedirectToAccountDetails() {
    if (this.isInsideIframe()) {
      window.parent.postMessage("CL_REDIRECT_ACCOUNT_DETAIL", '*');
      return true;
    }
    return false;
  }

  isInsideIframe() {
    return window.location !== window.parent.location;
  }

  getCurrentAccountIdToAssignOrder() {
    return this.currentAccountId;
  }

  setCurrentAccountIdToAssignOrder(accountId: string) {
    this.currentAccountId = accountId;
  }

  setReturnToOrigin( origin: string )
  {
    this.returnToOrigin = origin;
  }
}
