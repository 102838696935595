import { Injectable } from '@angular/core';
import { Router, NavigationExtras, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of  } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserClaims } from '@mitel/cloudlink-sdk';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { AppUserClaimsService } from './app-user-claims.service';


@Injectable()
export class RouteGuardService  {
 
  constructor( private appUserClaimsSvc: AppUserClaimsService,
            private myRoute: Router,
            private clHeader: ClHeaderComponent ) 
  {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> { // Observable<boolean> { //| Promise<boolean> | boolean {
      return this.appUserClaimsSvc.claimsChanged.asObservable().pipe(map( claims => {
          if ( claims.role == 'PARTNER_ADMIN')
          {
            return true;
          }
          else
          {
            //Good - forces Global_Admins from support portal to assume role first.
            this.clHeader.logoutUser(); //until I get a direction on how this is handled, we will just logout the user
            return false;
          }
      }));
  }
}
