import { Directive, ElementRef, AfterViewInit, Input, Renderer2, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { RBACAuthService } from '../services/rbac-auth.service';
import { ModeType } from '../utils/rbac-auth.util';

@Directive({
  selector: '[app-rbac]'
})
export class RBACAuthDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() category: string;
  /**
  * actions is an input array of actions that should be used for all new development as it allows
  * for one or more actions to be tested and provides greater flexibilty
  * Example of actions: app-rbac category="accounts" [actions]="['add', 'edit', 'delete']"
  */
  @Input() actions: string[] = [];
  /**
  * mode describes the action to apply when the element does not have permissions
  * default = remove
  */
  @Input() mode: string;

  subscription: Subscription = new Subscription();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private rbacAuthService: RBACAuthService
  ) { }

  ngAfterViewInit() {
    if (this.rbacAuthService.validPermissionsFlag) {
      this.checkPermission();
    } else {
      this.subscription.add(this.rbacAuthService.validPermissionsHandled.subscribe(newPermissions => {
        if (newPermissions) {
          this.checkPermission();
        }
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.rbacAuthService.validPermissionsFlag) {
      this.checkPermission();
    } else {
      this.subscription.add(this.rbacAuthService.validPermissionsHandled.subscribe(newPermissions => {
        if (newPermissions) {
          this.checkPermission();
        }
      }));
    }
  }

  checkPermission() {
    let applyRestrictions: boolean;
    if (this.actions.length) {
      this.actions.map(actionItem => {
        if (this.rbacAuthService.isPermissionDeniedForUser(this.category, actionItem)) {
          if (applyRestrictions === undefined) {
            applyRestrictions = true;
          }
        } else {
          applyRestrictions = false;
        }
      });
    }
    if (applyRestrictions) {
      this.applyRestrictionToElement();
    }
  }

  applyRestrictionToElement() {
    let element = this.el.nativeElement;
    switch (this.mode) {
      case ModeType.READONLY: {
        this.disableElement(element);
        break;
      }
      case ModeType.UNCHANGEABLE: {
        break;
      }
      case ModeType.HIDE: {
        this.hideElement(element);
        break;
      }
      default: {
        /**
        * We may want to test for readonly or tabindex -1 here and
        * treat the element as readonly
        */
        this.removeElement(element);
        break;
      }
    }
  }

  disableElement(element: any) {
    this.setAttributesIfApplicable(element);

    let tagName = element.tagName.toLowerCase();
    switch (tagName) {
      case 'button': {
        this.renderer.setProperty(element, 'disabled', 'true');
        this.renderer.addClass(element, 'mat-button-disabled');
        (element as any).removeAllListeners('click');
        break;
      }
      case 'input': {
        this.renderer.setAttribute(element, 'readonly', 'true');
        this.renderer.setProperty(element, 'disabled', 'true');
        (element as any).removeAllListeners('keyup');
        (element as any).removeAllListeners('keydown');
        (element as any).removeAllListeners('keypress');
        (element as any).removeAllListeners('change');
        break;
      }
      case 'mat-form-field': {
        this.renderer.addClass(element, 'mat-form-field-disabled');
        break;
      }
      case 'mat-slide-toggle': {
        this.renderer.addClass(element, 'mat-disabled');
        break;
      }
      case 'mat-select': {
        this.renderer.addClass(element, 'mat-select-disabled');
        (element as any).removeAllListeners('keyup');
        (element as any).removeAllListeners('keydown');
        (element as any).removeAllListeners('keypress');
        break;
      }
      case 'mat-icon': {
        this.renderer.addClass(element, 'disabled');
        break;
      }
      case 'ngx-intl-tel-input': {
        (element as any).removeAllListeners('click');
        break;
      }
      case 'label': {
        this.renderer.setStyle(element, 'cursor', 'default');
        (element as any).removeAllListeners('click');
        break;
      }
      case 'a': {
        this.renderer.addClass(element, 'disableLink');
        (element as any).removeAllListeners('click');
        (element as any).removeAllListeners('keyup');
        (element as any).removeAllListeners('keydown');
        (element as any).removeAllListeners('keypress');
        break;
      }
      case 'span': {
        if (element.classList.contains('slider') && element.classList.contains('round')) {
          this.renderer.addClass(element, 'disabled');
        }
        break;
      }
      case 'div': {
        if (element.classList.contains('mat-mdc-select-trigger') || element.classList.contains('mat-mdc-form-field-flex')) {
          (element as any).removeAllListeners('click');
        }
        if (element.classList.contains('dropdown-toggle')) {
          (element as any).removeAllListeners('click');
        }
        if (element.classList.contains('settings-icon') ||
          element.classList.contains('action-icon')) {
          this.renderer.addClass(element, 'disabled');
          (element as any).removeAllListeners('click');
          (element as any).removeAllListeners('keyup');
          (element as any).removeAllListeners('keydown');
          (element as any).removeAllListeners('keypress');
        }
        break;
      }
      default: {
        break;
      }
    }
    if (element.children && element.children.length) {
      Array.from(element.children).forEach(child => {
        this.disableElement(child);
      });
    }
  }

  setAttributesIfApplicable(element: any) {
    const NG_REFLECT_DISABLED = 'ng-reflect-disabled';
    const NG_REFLECT_IS_DISABLED = 'ng-reflect-is-disabled';
    const ARIA_DISABLED = 'aria-disabled';
    const TAB_INDEX = 'tabindex';

    this.setAttribute(element, NG_REFLECT_DISABLED, 'true');
    this.setAttribute(element, NG_REFLECT_IS_DISABLED, 'true');
    this.setAttribute(element, ARIA_DISABLED, 'true');
    this.setAttribute(element, TAB_INDEX, '-1');
  }

  setAttribute(element: any, attribute: string, newValue: string): void {
    if (element['attributes'][attribute]) {
      this.renderer.setAttribute(element, attribute, newValue);
    }
  }

  hideElement(element) {
    this.renderer.setStyle(element, 'display', 'none');  
  }

  removeElement(element: any): void {
    this.renderer.removeChild(element.parentNode, element);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
