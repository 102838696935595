import { User } from "@mitel/cloudlink-sdk";
import { AccessPolicy } from "@mitel/cloudlink-sdk/admin";

export enum UserRoles {
	USER = 'USER',
	TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
	ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
	PARTNER_ADMIN = 'PARTNER_ADMIN',
	GLOBAL_ADMIN = 'GLOBAL_ADMIN'
}

export enum BaseRoleType {
  PARTNER = 'PARTNER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  USER = 'USER'
}

export enum RoleAssignOperation {
  ASSIGN = 'Assign',
  UNASSIGN = 'Unassign',
  UPDATE = 'Update',
}

export enum ModeType {
	HIDE = 'hide',
	READONLY = 'readonly',
	UNCHANGEABLE = 'unchangeable'
}

export enum ActionType {
  VIEW = 'view',
  ADD = 'add',
  EDIT = 'edit',
  ASSIGN = 'assign',
  DELETE = 'delete'
}

export enum CategoryId {
  USERS = 'users',
  USERSBULK = 'usersBulk',
  ROLES = 'rolesManagement',
  INTEGRATIONS = 'integrationApps',
  INTEGRATIONMICC = 'integrationMicc',
  INTEGRATIONMICOLLAB = 'integrationMiCollab',
  INTEGRATIONGATEWAY = 'integrationGateway',
  INTEGRATIONSSO = 'integrationSSOAzure',
  INTEGRATIONAZURE = 'integrationSyncAzure',
  INTEGRATIONOKTA = 'integrationSyncOKTA',
  INTEGRATIONOFFICE365 = 'integrationOffice365',
  INTEGRATIONMITELONE = 'integrationMitelOne',
  INTEGRATIONVOICEASSIST = 'integrationVoiceassist',
  INTEGRATIONMITELINSIGHTS = 'integrationInsights',
  INTEGRATIONWORKGROUPS = 'integrationWorkgroups',
  INTEGRATIONMIVOICE5000 = 'integrationMiVoice5000',
  INTEGRATIONPHONEPRESENCE = 'integrationPhonepresence',
  INTEGRATIONMSTEAMS = 'integrationMSteams',
  USERSLICENSE = 'usersLicenses',
  ACCOUNT = 'account'
}

//RBAC const
export const rbacPolicyConst = {
  type: '-rbac-policy', //temp until 'policy type' is implemented
  divider: ':',
  assignToAccounts: 'assignToAccounts',
  assignToAccountsFeature: 'https://mitel.io/auth/rbac/features/assign/accounts/',
  assignToUsers: 'assignToUsers',
  assignToUsersFeature: 'https://mitel.io/auth/rbac/features/assign/users/',
  rbacUserTagName: 'rbac',
  rbacUserTagId: 'id',
  actionFeature: 'https://mitel.io/auth/rbac/features/'
};

export interface Permission {
  role: string;
  category: string;
  action: string;
  resource: string;
}

export class rbacUtil {
  static matchingBaseRoleType(userRole: User.RoleEnum): BaseRoleType {
    let brt: BaseRoleType;
    switch (userRole) {
      case UserRoles.PARTNER_ADMIN:
        brt = BaseRoleType.PARTNER;
        break;
      case UserRoles.ACCOUNT_ADMIN:
        brt = BaseRoleType.ADMINISTRATOR
        break;
      case UserRoles.USER:
        brt = BaseRoleType.USER
        break;
      default:
        console.log(`not matching base role type for userRole ${userRole}`);
    }
    return brt;
  }

  static getTagPropertyById(user: User, tagId: string, tagProperty: string): string {
    let tag = ''
    if (user.tags && user.tags[tagId]) {
      tag = { ...user }.tags[tagId][tagProperty];
    }
    return tag || '';
  }

  static compareStringsCaseInsensitive(a: string, b: string): boolean {
    return a.toLocaleLowerCase() === b.toLocaleLowerCase();
  }
}