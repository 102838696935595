import { AppInfo } from "@mitel/cloudlink-sdk";
import { version } from './version';

export const environment = {
	production: false,
	cloud:  'dev',
	clientId: '946d9b29a9f748e48944acaaec2d67f4',
	subscriptionIds:
	{
		MiTeam: ["2c92c0f86ded1784016dee882a7033e4", "8ad098317f3a75bf017f4688d4a53f88"],
		ContactCenter: ["2c92c0f9778c090001778dbffa574583"],
		MitelOne: ["2c92c0f9786d37cf01787020b8f76079", "2c92c0f878ac402c0178b1d79bce6615", "8ad0965d7f3a75c3017f46898517171c"],
		MiVoice5000: ["8ad098317f54fa89017f65772f1618ab"],
		MiVoiceBusiness: ['8ad08c0f7cc21783017cc2c66e165b1e', '8ad094b9858682c401859c029bf93a00']
	},
	appInfo: new AppInfo({
		correlationIdPrefix: 'cl-billing-web',
		app: {
		  name: 'cl-billing-web',
		  version: version.appVersion,
		},
		enableAppHeader: true,
	  })
};
