import { Injectable } from '@angular/core';
import { ClHeaderComponent } from '@mitel/cloudlink-console-components';
import { ParentCommsService } from './parent-comms.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorsHandlerService {

	constructor(
		private clHeader: ClHeaderComponent,
		private parentCommsService: ParentCommsService
	) { }

	handleError(error: any): void {
		console.error("handleError: ", error);

		if (error.statusCode) {
			switch (error.statusCode) {
				case 403: {
					console.log('Function: handleError: ', "Unauthorized 403");
					this.clHeader.logoutUser();
					break;
				}
				case 401: {
					console.log('Function: handleError: ', "Unauthorized 401");
					if (this.parentCommsService.tellParentToRedirectToLogin() == false)
						this.clHeader.redirectToLogin(); // let session management figure it out
					break;
				}
			}
		}

		if (error instanceof Error) {
			console.log('Function: handleError, : ', 'instance of Error');
			if (error.message === 'No token passed and no token could be found in localstorage. Please login and / or pass a valid token') {
				if (this.parentCommsService.tellParentToRedirectToLogin() == false)
					this.clHeader.redirectToLogin(); // let session management figure it out
			}
		}

	}
}
