<div id="container">
  <cl-header
    appLabelName="Billing"
    [enableCompanySwitcher]="false" 
    [enableCurrentAccountDisplay]="false"
    (userClaimsUpdated)="handleUserClaims($event)"
    (goHomePage)="handleGoHome()"
    [cloud]="cloud"
    (authConfirmedInvokingUrl)="handleInvokingUrl($event)"
  ></cl-header>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
</div>

