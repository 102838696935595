import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'form-error-alert',
  templateUrl: './form-error-alert.component.html',
  styleUrls: ['./form-error-alert.component.scss']
})
export class FormErrorAlertComponent implements OnInit {
  @Input() errorMessage: string;
  @Input() externalStyles: any = {};
  @Input() id: string = 'theAlert';

  constructor(public translateSvc: TranslateService) { }

  ngOnInit() {

  }


}