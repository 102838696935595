import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  spinnerStatusChanged = new Subject<boolean>();
  spinnerMessageKeyChanged = new Subject<string>();
  private isShown = false;
  private spinnerMessageKey = 'app.loading';

  constructor(
    private translateService: TranslateService,
  ) { }

  showSpinner() {
    let oldValue = this.isShown;
    this.isShown = true;
	  // console.log('Function: showSpinner, isShown: ', this.isShown);
	  if (!oldValue) {
		  this.spinnerStatusChanged.next(this.isShown);
	  }
  }

  hideSpinner() {
    this.isShown = false;
    this.spinnerStatusChanged.next(this.isShown);
  }

  isSpinnerShown(): boolean {
    return this.isShown;
  }

  setSpinnerMessageByKey(key: string) {
    this.spinnerMessageKey = key;
    this.spinnerMessageKeyChanged.next(this.spinnerMessageKey);
  }

  getSpinnerMessageKey(): string {
    return this.spinnerMessageKey;
  }

  getTranslatedMessage(): string {
    return this.translateService.instant(this.spinnerMessageKey);
  }
}
